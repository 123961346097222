var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ul',{staticClass:"simplebar nav nav-tabs mb-4 bg-white mx-1 br-radius-10",staticStyle:{"flex-wrap":"wrap","white-space":"inherit"}},_vm._l((_vm.tabs),function(tab,index){return _c('li',{key:tab.id,staticClass:"col button-box justify-content-center",class:{
        active: _vm.selectedHTabId === tab.id,
        'first-button': index == 0,
        'last-button': index == _vm.tabs.length - 1
      },attrs:{"role":"button"},on:{"click":function($event){return _vm.changeTab(tab.id)}}},[_c('a',{staticClass:"btn-2",class:{
          active: _vm.selectedHTabId === tab.id,
          'active first-button': index == 0,
          'last-button': index == _vm.tabs.length - 1
        }},[_vm._v(_vm._s(tab.title))])])}),0),(_vm.selectedHTabId)?_c('div',{ref:"hTabContent"},[_vm._t("default")],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }