<template>
  <a :href="url" :target="openInNewTab ? '_blank' : false">
    {{ title }}
  </a>
</template>

<script>
  export default {
    props: {
      title: String,
      url: String,
      openInNewTab: Boolean
    }
  }
</script>
