import './axios'
import 'bootstrap'
import './jquery'
import './iconfont'
import './fancybox'

import Vue from 'vue'
import VueSplide from '@splidejs/vue-splide'

Vue.use(VueSplide)

window.Popper = require('popper.js').default
