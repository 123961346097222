<template>
  <div class="row">
    <div v-if="selectedTabId" ref="tabContent" class="col-9">
      <slot></slot>
    </div>
    <div class="col-3">
      <div class="vtab-controls d-flex flex-column">
        <p class="font-weight-bold">
          {{ maintext }}
        </p>
        <button
          v-for="tab in tabs"
          :key="tab.id"
          @click="changeTab(tab.id)"
          class="vtab-button"
          :class="selectedTabId === tab.id && 'active'"
        >
          {{ tab.title }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'VerticalTabs',
    props: {
      maintext: String,
      tabs: Array
    },
    data() {
      return {
        selectedTabId: null
      }
    },
    methods: {
      changeTab(newId) {
        this.$refs.tabContent.classList.add('vtab-content--leave')

        this.$refs.tabContent.querySelectorAll('.vtab-panel').forEach(tab => {
          tab.classList.remove('vtab-panel--active')
          if (tab.getAttribute('data-tab') === newId) {
            tab.classList.add('vtab-panel--active')
          }
        })
        setTimeout(() => {
          this.$refs.tabContent.classList.remove('vtab-content--leave')
          this.$refs.tabContent.classList.add('vtab-content--enter')
          this.selectedTabId = newId
        }, 400)
        this.$refs.tabContent.classList.remove('vtab-content--enter')
      }
    },
    mounted() {
      this.selectedTabId = this.tabs[0].id
      document.addEventListener('DOMContentLoaded', () => {
        this.changeTab(this.selectedTabId)
      })
    }
  }
</script>
<style lang="sass">
  .vtab
    &-panel
      display: none
      &--active
        display: block
    &-controls
      border-left: 4px solid #121143
      padding-left: 15px
    &-button
      background: none
      outline: none
      font-weight: bold
      border: none
      color: #121143
      text-align: left
      padding: 0
      margin-bottom: 8px
      transition: .2s
      &:hover
        transform: scaleX(1.02)
      &:focus
        outline: none
      &.active
        color:#2521F2
        text-decoration: underline
</style>
