var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{ref:"form",staticClass:"d-flex lang-selector",attrs:{"action":_vm.action,"method":"post"}},[_c('input',{attrs:{"name":"next","type":"hidden"},domProps:{"value":_vm.nextUrl}}),_vm._t("default"),_c('input',{ref:"language",attrs:{"name":"language","type":"hidden"}}),_c('div',[_c('vselect',{staticClass:"w-100",attrs:{"value":_vm.value,"options":_vm.options,"clearable":false,"searchable":false},on:{"input":_vm.changeEvent},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var label = ref.label;
var id = ref.id;
return [_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('country-flag',{attrs:{"country":id == 'en' ? 'gb' : id,"size":"small"}}),_c('span',{staticClass:"pl-2"},[_vm._v(_vm._s(label))])],1)]}},{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('i',{staticClass:"mdi mdi-chevron-down mdi-18px"})])]}},{key:"option",fn:function(ref){
var label = ref.label;
var id = ref.id;
return [_c('country-flag',{attrs:{"country":id == 'en' ? 'gb' : id,"size":"small"}}),_c('span',{staticClass:"pl-2"},[_vm._v(_vm._s(label))])]}}])})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }