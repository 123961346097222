import { Fancybox } from '@fancyapps/ui'

Fancybox.bind('[data-fancybox="news-gallery"]', {
  Thumbs: false,
  Toolbar: false,

  Image: {
    zoom: false,
    click: false,
    wheel: 'slide'
  }
})
