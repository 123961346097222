import $ from 'jquery'
import 'jquery.maskedinput/src/jquery.maskedinput'

$(function() {
  $('input[type="radio"]').change(function() {
    var labelRadio = $('#' + this.id).parent($('label'))
    $('.form__help-text > ul > li > label').addClass('form__radio-passive')
    $('.form__help-text > ul > li > label').removeClass('form__radio-active')
    labelRadio.removeClass('form__radio-passive')
    labelRadio.addClass('form__radio-active')
  })
  $('.accordion > .card').click(function() {
    $('.accordion > .card').removeClass('accordion__active')
    if (!$('#' + this.id).children('.show').length > 0) {
      $('#' + this.id).addClass('accordion__active')
    }
  })
  $('#phone1').mask('+7(999) 999-9999')
  $(document).ready(function() {
    $('a[href^="#feedback-form"]').click(function() {
      var target = $(this).attr('href')
      $('html, body').animate({ scrollTop: $(target).offset().top - 400 }, 1000)
      return false
    })
  })
  if ($('.nav-tabs').length > 0) {
    $(document).ready(function() {
      let url = location.href.replace(/\/$/, '')
      if (location.hash) {
        const hash = url.split('#')
        $('a[href="#' + hash[1] + '"]').tab('show')
        url = location.href.replace(/\/#/, '#')
        history.replaceState(null, null, url)
        setTimeout(() => {
          $(window).scrollTop($('#' + hash[1]).offset().top)
        }, 400)
      }
    })
  }
})
