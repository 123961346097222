import { Splide, SplideSlide } from '@splidejs/vue-splide'
import simplebar from 'simplebar-vue'
import Vue from 'vue'
import { Carousel3d, Slide } from 'vue-carousel-3d'
import CountryFlag from 'vue-country-flag'
import LazyYoutubeVideo from 'vue-lazy-youtube-video'
import vSelect from 'vue-select'
import App from './layout/App'
import TopMenu from './layout/TopMenu'
import Example from './ui/Example'
import LangSelector from './ui/LangSelector'
import VerticalTabs from './ui/VerticalTabs'
import HorizontalTabs from './ui/HorizontalTabs'
[
  App,
  {
    name: 'example',
    ...Example
  },
  {
    name: 'country-flag',
    ...CountryFlag
  },
  {
    name: 'vselect',
    ...vSelect
  },
  {
    name: 'sarousel3d',
    ...Carousel3d
  },
  {
    name: 'slide',
    ...Slide
  },
  {
    name: 'splide-slide',
    ...SplideSlide
  },
  {
    name: 'splide',
    ...Splide
  },
  {
    name: 'vertical-tabs',
    ...VerticalTabs
  },
  {
    name: 'horizontal-tabs',
    ...HorizontalTabs
  },
  LangSelector,

  TopMenu
  // if not component name
  // { name: 'component-name', ...Component }
].forEach(Component => {
  if (!Component.name) {
    throw new Error(`Not component name: ${Component}`)
  }

  Vue.component(Component.name, Component)
})

Vue.component('lazyyoutubevideo', LazyYoutubeVideo)
Vue.component('simplebar', simplebar)
