<template>
  <nav>
    <ul class="nav-menu">
      <li v-for="(item1, index1) in menu" :key="index1">
        <Link
          :url="item1.url"
          :title="item1.title"
          :openInNewTab="item1.openInNewTab"
        />
        <div class="nav-menu__dropdown">
          <Link
            v-for="(item2, index2) in item1.subNav"
            :key="`${index1}_${index2}`"
            :url="item2.url"
            :title="item2.title"
            :openInNewTab="item2.openInNewTab"
          />
        </div>
      </li>
    </ul>
  </nav>
</template>

<script>
  import Link from './Link'
  export default {
    name: 'topmenu',
    components: {
      Link
    },
    data() {
      return {
        menu: []
      }
    },
    props: {
      menuJson: {
        type: String,
        default: ''
      }
    },
    created() {
      if (this.menuJson) {
        this.menu = JSON.parse(this.menuJson)
      }
    }
  }
</script>
