<template>
  <div>
    <ul
      class="simplebar nav nav-tabs mb-4 bg-white mx-1 br-radius-10"
      style="flex-wrap: wrap; white-space: inherit;"
    >
      <li
        :key="tab.id"
        v-for="(tab, index) in tabs"
        @click="changeTab(tab.id)"
        class="col button-box justify-content-center"
        role="button"
        :class="{
          active: selectedHTabId === tab.id,
          'first-button': index == 0,
          'last-button': index == tabs.length - 1
        }"
      >
        <a
          class="btn-2"
          :class="{
            active: selectedHTabId === tab.id,
            'active first-button': index == 0,
            'last-button': index == tabs.length - 1
          }"
          >{{ tab.title }}</a
        >
      </li>
    </ul>

    <div v-if="selectedHTabId" ref="hTabContent">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'HorizontalTabs',
    props: {
      tabs: Array
    },
    data() {
      return {
        selectedHTabId: null
      }
    },
    methods: {
      changeTab(newId) {
        this.$refs.hTabContent.classList.add('htab-content--leave')

        this.$refs.hTabContent.querySelectorAll('.htab-panel').forEach(tab => {
          tab.classList.remove('htab-panel--active')
          if (tab.getAttribute('data-htab') === newId) {
            tab.classList.add('htab-panel--active')
          }
        })
        setTimeout(() => {
          this.$refs.hTabContent.classList.remove('htab-content--leave')
          this.$refs.hTabContent.classList.add('htab-content--enter')
          this.selectedHTabId = newId
        }, 200)
        this.$refs.hTabContent.classList.remove('htab-content--enter')
      }
    },
    mounted() {
      this.selectedHTabId = this.tabs[0].id
      document.addEventListener('DOMContentLoaded', () => {
        this.changeTab(this.selectedHTabId)
      })
    }
  }
</script>
<style lang="sass">
  .htab
    &-panel
      display: none
      &--active
        display: block
    &-controls
      border-left: 4px solid #121143
      padding-left: 15px
    &-button
      background: none
      outline: none
      font-weight: bold
      border: none
      color: #121143
      text-align: left
      padding: 0
      margin-bottom: 8px
      transition: .2s
      &:focus
        outline: none
      &.active
        color:#2521F2
        text-decoration: underline
</style>
