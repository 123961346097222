<template>
  <form class="d-flex lang-selector" :action="action" method="post" ref="form">
    <input name="next" type="hidden" :value="nextUrl" />
    <slot></slot>
    <input ref="language" name="language" type="hidden" />
    <div>
      <vselect
        class="w-100"
        @input="changeEvent"
        :value="value"
        :options="options"
        :clearable="false"
        :searchable="false"
      >
        <template #selected-option="{ label, id }">
          <div style="display: flex; align-items: center;">
            <country-flag :country="id == 'en' ? 'gb' : id" size="small" />
            <span class="pl-2">{{ label }}</span>
          </div>
        </template>

        <template #open-indicator="{ attributes }">
          <span v-bind="attributes">
            <i class="mdi mdi-chevron-down mdi-18px"></i>
          </span>
        </template>

        <template v-slot:option="{ label, id }">
          <country-flag :country="id == 'en' ? 'gb' : id" size="small" />
          <span class="pl-2">{{ label }}</span>
        </template>
      </vselect>
    </div>
  </form>
</template>

<script>
  export default {
    name: 'langselector',
    props: {
      default: String,
      nextUrl: String,
      action: String,
      options: Array
    },
    created() {
      this.value = this.options.find(el => el.id === this.default)
    },
    data() {
      return {
        value: null,
        currentLanguage: ''
      }
    },
    methods: {
      changeEvent(val) {
        this.$refs.language.value = val.id
        this.$refs.form.submit()
      }
    }
  }
</script>
